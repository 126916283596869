import React from "react";
import "./Testimonials.css";
import { Swiper, SwiperSlide } from "swiper/react";
import megha from "../../img/meghalaya.jpeg";
import delhi from "../../img/iit delhi.png";
import "swiper/css";
const Portfolio = () => {
  return (
    <div className="testi" id="Awards">
      {/*heading*/}
      <span>Achievements</span>
      <span>and</span>
      <span>Awards</span>
      {/*sliuuder*/}
      <Swiper
        spaceBetween={30}
        slidesPerView={2}
        grabCursor={true}
        className="testi-slider"
      >
        <SwiperSlide>
          <img src={megha} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={delhi} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;
