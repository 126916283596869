import React from "react";
import Navbar from "./Components Navbar/Navbar";
import './App.css';
import Intro from "./Components Navbar/Intro/Intro";
import Services from "./Components Navbar/Services/Services";
import Works from "./Components Navbar/Works/Works";
import Portfolio from "./Components Navbar/Portfolio/Portfolio";
import Testimonials from "./Components Navbar/Testomonials/Testimonials";
import Contact from "./Components Navbar/Contact/Contact";
import Footer from "./Components Navbar/Footer/Footer";





function App() {
  return (
    <div className="App">
      <Navbar/>
      <Intro/>
      <Services/>
       <Works/>
       <Portfolio/>
       <Testimonials/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
