import React from "react";
import "./Intro.css";
import GitHub from "../../img/github.png";
import Linkedin from "../../img/linkedin.png";

import vector1 from "../../img/Vector1.png";
import vector2 from "../../img/Vector2.png";
import boy from "../../img/final.png";
import crown from "../../img/crown.png";
import glass from "../../img/glassesimoji.png";
import FloatingDiv from "../FloatingDiv/FloatingDiv";

const Intro = () => {
  return (
    <div className="intro">
      <div className="i-left">
        <div className="i-name">
          <span
            style={{
              fontSize: "3rem",
              fontWeight: "bold",
              color: "var(--black)",
            }}
          >
            Namaste! I Am
          </span>
          <span
            style={{
              fontSize: "3rem",
              fontWeight: "bold",
              color: "var(--orange)",
            }}
          >
            Rahul Chakradhari
          </span>
          <span style={{ color: "var(--black)" }}>
            Aspiring Front End Developer with a keen interest in SQL databases
            and a passion for data-driven solutions. Familiarity with SQL
            queries and database management systems, eager to expand skills in
            data manipulation and analysis. Basic understanding of front-end
            frameworks like React, with a desire to further develop expertise in
            this area.
          </span>
        </div>
        <button className="button i-button">Hire Me</button>
        <div className="i-icons">
          <a href="https://www.linkedin.com/in/rahul-chakradhari-17a437217">
            <img src={Linkedin} alt="GitHub" />
          </a>

          <a href="https://github.com/rahul-chakradhari/Rahul.git">
            <img src={GitHub} alt="LinkedIn" />
          </a>
        </div>
      </div>
      <div className="i-right">
        <img src={vector1} alt="" />
        <img src={vector2} alt="" />
        <img src={boy} alt="" />
        <div style={{ width: "100px", height: "90px" }}>
          <img src={glass} alt="" />
        </div>

        <div style={{ top: "-3%", left: "60%" }}>
          <FloatingDiv image={crown} txt1="web" txt2="developer" />
        </div>
        {/*blur classes*/}
        <div
          className="blur"
          style={{
            background: "#c1f5ff",
            top: "17rem",
            width: "21rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>
        <div style={{ top: "22rem", left: "5%" }}></div>
      </div>
    </div>
  );
};

export default Intro;
