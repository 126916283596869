import React from "react";
import sql from "../../img/mysql.png";
import css from "../../img/CSS-Logo.png";
import html from "../../img/html.png";
import powerbi from "../../img/powerBI.png";
import react from "../../img/react.js-img.png";
import "./Works.css";

const Works = () => {
  return (
    <div className="works" id="Works">
      {/*left-side*/}
      <div className="awesome">
        <span>Worked With</span>
        <span>Softwares</span>
        <span>
          Passionate data analyst with a proven track record of
          <br />
          extracting valuable insights from complex datasets.
          <br />
          Proficient in SQL and PowerBI, dedicated to
          <br />
          delivering high-quality results and driving
          <br />
          informed decision-making
        </span>
        <button className="button s-button">Hire Me</button>
        <div
          className="blur1"
          style={{ background: "rgba(255, 141, 242, 0.2)" }}
        ></div>
      </div>
      {/*right-side*/}
      <div className="w-right">
        <div className="w-maincircle">
          <div className="w-seccircle">
            <img src={html} alt="" />
          </div>
          <div className="w-seccircle">
            <img src={css} alt="" />
          </div>
          <div className="w-seccircle">
            <img src={react} alt="" />
          </div>
          <div className="w-seccircle">
            <img src={powerbi} alt="" />
          </div>
          <div className="w-seccircle">
            <img src={sql} alt="" />
          </div>
        </div>

        {/*background circles*/}
        <div className="w-backcircle blue-circle"></div>
        <div className="w-backcircle yellow-circle"></div>
      </div>
    </div>
  );
};

export default Works;
