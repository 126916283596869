import React from "react";
import "./Footer.css";
import wave from "../../img/wave.png";
import Whats from "@iconscout/react-unicons/icons/uil-whatsapp";
import Tele from "@iconscout/react-unicons/icons/uil-telegram";

const Footer = () => {
  return (
    <div className="footer">
      <img src={wave} style={{ width: "100%" }} alt="" />
      <div className="wave-content">
        <div className="f-content">
          <span className="email">rahulchakradhari10september@gmail.com</span>
          <div className="f-icons">
            <Whats color="white" size="3rem" />
            <Tele color="white" size="3rem" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
