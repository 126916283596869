import React from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react";
import power from "../../img/power.png";
import react from "../../img/reactjs.jpg";
import sql from "../../img/sql.png";
import "swiper/css";
const Portfolio = () => {
  return (
    <div className="portfolio" id="Portfolio">
      {/*heading*/}
      <span>Recent Projects</span>
      <span>Portfolio</span>
      {/*sliuuder*/}
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
          <img src={power} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={react} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={sql} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;
