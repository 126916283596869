import React from "react";
import "./Services.css";
import Heart from "../../img/heartemoji.png";
import glasses from "../../img/glasses.png";
import Cards from "../Cards/Cards";

const Services = () => {
  return (
    <div className="services" id="Services">
      {/* Left blur effect */}
      <div className="blur left-blur"></div>

      <div className="awesome">
        <span>My Awesome</span>
        <span>services</span>
        <spane>
          Dedicated professional with expertise in data analysis
          <br />
          skilled in SQL, PowerBI, committed to achieving excellence.
        </spane>
        <a href="https://drive.google.com/file/d/1N0W9Ntw9sxLZTDIWZI4xY2mMxXQ8IAwv/view?usp=sharing">
          <button className="button s-button">Download CV</button>
        </a>

        {/* Right blur effect */}
        <div className="blur right-blur"></div>
      </div>

      <div className="cards">
        <div style={{ left: "14rem" }}>
          <Cards emoji={Heart} heading={"Analysis"} detail={"SQL, PowerBI"} />
        </div>
        <div style={{ top: "15rem", left: "-8rem" }}>
          <Cards
            emoji={glasses}
            heading={"Development"}
            detail={"HTML, CSS, ReactJS"}
          />
        </div>
        <div
          className="s-blur2"
          style={{ background: "#B95CF4", filter: "blur(10px)" }}
        ></div>
      </div>
    </div>
  );
};

export default Services;
